'use strict';

angular.module('windmanagerApp')
  .directive('listTurbine', function($compile) {
    return {
      templateUrl: 'components/directives/turbine/listTurbine/listTurbine.html',
      restrict: 'EA',
      scope: {
        turbines: '=',
        turbineFindings: '=',
        showMergeOption: '=',
        sortKey: '='
      },
      controller: 'ListTurbineCtrl',
      controllerAs: 'ListTurbine',
      link: function(scope, element, attrs) {
      }
    };
  });
